import { useSSRSelector } from '@/redux/ssrStore';

export const CollectionCategorySideRender = ({
  collectionCategoryString,
  setAccordionId,
  categoryClickedToBeScrolled,
}) => {
  const { collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
  }));

  const accordionHeaderClick = (e) => {
    e.stopPropagation();
    const dataId = e.currentTarget.getAttribute('data-id');
    if (Number(dataId) === collectionCategoryString.collectionId) {
      setAccordionId(null);
    } else {
      setAccordionId(Number(dataId));
    }
  };
  const scrollToCategoryOnClick = (id, categoryId) => {
    categoryClickedToBeScrolled.current = categoryId;
    document && document.getElementById(id).scrollIntoView();
  };

  const calculateOffsetTop = () => {
    const parentElement = document.getElementsByClassName('preHome-page')[0];
    const header = parentElement?.firstChild;

    return header ? `${header?.offsetHeight + 3}px` || 0 : 0;
  };

  if (collections?.length === 0) return null;

  return (
    <div
      className="CollectionCategorySideRender"
      style={{
        top: calculateOffsetTop(),
        overflowY: 'scroll',
        maxHeight: `calc(100vh - ${calculateOffsetTop()} )`,
      }}
    >
      <div className="collection-accordion">
        {/* accordion heading */}
        {collections?.map((collection) => (
          <div className="collection-accordion__container" key={collection.id}>
            <div
              className="flex items-center justify-between collection-accordion__header pointer"
              data-id={collection.id}
              onClick={accordionHeaderClick}
            >
              <p
                className="mv0 tw-max-w-[80%]"
                style={{ overflow: 'scroll', scrollbarWidth: 'none' }}
              >
                {collection.name}
              </p>
              <img
                alt="Dropdown button"
                style={{
                  transform: `rotate(${
                    collectionCategoryString.collectionId === collection.id
                      ? '180deg'
                      : '0deg'
                  })`,
                }}
                src="/assets/images/down-arrow-thin.svg"
              />
            </div>
            {/* accordion body */}
            {collectionCategoryString.collectionId === collection.id && (
              <div className="collection-accordion__body">
                <ul className="body-list">
                  {collection?.categories?.map((category) => (
                    <li
                      key={category.id}
                      id={category.id}
                      className={`pointer body-list--item ${
                        `${collectionCategoryString.collectionId}-${collectionCategoryString.categoryId}` ===
                        `${collection.id}-${category.id}`
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        scrollToCategoryOnClick(
                          `${collection.id}-${category.id}`,
                          category.id
                        )
                      }
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
